
<template>
  <div
      :id="random_id"
      v-bind="$attrs"
      :data-testid="props.dataTestid"
      :class="$attrs.class || twMerge('flex items-center pt-4 pb-0 lg:pb-2.5', rootClass)"
  >
    <template v-if="isMobile && breadCrumbData.length > 0" :key="breadCrumbData[0].text">
      <TsTypography
          append-class="text-idle-black"
          size="sm"
      >
        <span>Back to &nbsp;</span>
      </TsTypography>
      <TsTypography
          as="NuxtLink"
          :to="localePath(breadCrumbData[0].link)"
          :append-class="twMerge(
              'mb-0',
              props.linkClass,
              index === breadCrumbData - 1 && 'text-idle-black'
          )"
          size="sm"
      >
        <span>{{ breadCrumbData[0].text }}</span>
      </TsTypography>
    </template>
    <template v-else v-for="(item, index) in breadCrumbData" :key="item.text">
      <TsTypography
          as="NuxtLink"
          :to="localePath(item.link)"
          :append-class="twMerge(
              'mb-0',
              props.linkClass,
              index === breadCrumbData - 1 && 'text-idle-black'
          )"
          size="sm"
      >
        <span>{{ item.text }}</span>
      </TsTypography>

      <TsIcon
          v-if="index !== breadCrumbData.length - 1"
          class="mx-1 lg:mx-2"
          name="flowbite:angle-right-outline"
          size="16"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import {ref, onMounted , defineOptions} from 'vue';
import type { Ref } from 'vue';
import { twMerge } from "tailwind-merge";
import {useRandomUUID} from "../../composables/useRandomUUID";
const localePath = useLocalePath();
const { isMobile } = useDevice()

const random_id: Ref<string> = ref("");

// Generating random ID::
onMounted(() => {
  random_id.value = useRandomUUID();
});

type Link = {
  text?: string;
  link?: string;
}

type Props = {
  dataTestid?:string;
  data?: Link[];
  rootClass?: string;
  linkClass?: string;
};

const props = withDefaults(defineProps<Props>(), {
  data: () => ([
    {
      text: 'Home',
      link: '#'
    }
  ])
})

const breadCrumbData = computed(() => {
  return isMobile && props.data.length > 1 ? [props.data.at(props.data.length - 2)] : props.data
})

</script>
